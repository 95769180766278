table thead .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
   }
   
   table thead .dropup .caret,
   table thead .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
   }

.create-button{
    float: right;
    margin-bottom: 2%;
}
.create-button:hover, .create-button:active{
    cursor: pointer;
    background-color: navy;
}

.verification{
    border: none;
    background-color: blue;
    color: white;
    padding: 0.5%;
}
.verification:hover, .verification:active{
    background-color: navy;
    cursor: pointer;
}